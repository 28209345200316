export default function () {
  const status = useState('toast-notification', () => {
    return {
      type: 'info',
      message: '',
      time: 0,
      show: false
    } as ToastStatus
  })
  return status
}
